// TicketSlider.tsx
import React, { useEffect, useState } from "react";
import moment from "moment";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
// import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import varifiedSvg from "../../Assets/verified.svg";
import {
  CreateOrder,
  ValidateCouponCode,
  ValidateMobileNo,
  SendMobileNo,
} from "containers/store";

import { SetupDefaultcompany } from "containers/store";
// import { title } from "process";
import { SweetToast } from "utils/sweetAlert";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";

// Define Event type with required properties
interface Event {
  id: string;
  ticket_title: string;
  price: number;
  service_fees: number;
  convenience_fees: number;
  // Add other properties as needed
}
// Extend Event type with additional properties
interface ExtendedEvent extends Event {
  ticket_title: string;
  price: number;
  service_fees: number;
  convenience_fees: number;
}

interface TicketSliderProps {
  isVisible: boolean;
  showSlide: boolean;
  Event: ExtendedEvent;
  eventTicketData: any;
  handleCloseClick: () => void;
}

interface FormData {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  checkBox: boolean;
  freeDate: null;
}

const initialValues: FormData = {
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  checkBox: false,
  freeDate: null,
};

const validationSchema = Yup.object({
  first_name: Yup.string().required("First Name is required"),
  last_name: Yup.string().required("Last Name is required"),
  phone: Yup.string()
    .matches(/^\d{10}$/, "Phone must be exactly 10 digits")
    .required("Phone is required"),

  checkBox: Yup.boolean()
    .oneOf([true], "You must agree to the terms and conditions.")
    .required("You must agree to the terms and conditions."),
});

export const TicketSlider: React.FC<TicketSliderProps> = ({
  isVisible,
  showSlide,
  Event,
  eventTicketData,
  handleCloseClick,
}) => {
  // const navigate = useNavigate();
  console.log(eventTicketData, "eventTicketData");

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMsgSent, setIsMsgSent] = useState(false);
  const [cooldown, setCooldown] = useState(0);
  const [isCouponLoading, setIscouponLoading] = useState(false);
  const [verified, setVerified] = useState(false);
  const [otpShow, setOtpShow] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [otp, setOTP] = useState("");
  const [couponApplied, setCouponApplied] = useState(false); // State to track coupon application
  const [couponDiscount, setCouponDiscount] = useState(0); // State to track the discount
  const [couponData, setCouponData] = useState(null);
  const [total, setTotal] = useState(0);
  const [freedate, setFreedate] = useState<any>();
  const [formattedFreeDate, setFormattedFreeDate] = useState<any>();

  // const total = calculateTotal(eventTicketData);

  
  useEffect(() => {
    let freedate = couponApplied ? couponData : null;
    let formattedFreeDate = moment(freedate).format("D MMM");
    setFreedate(freedate);
    setFormattedFreeDate(formattedFreeDate);
  }, [couponApplied]);

  // Define calculateTotal function
  const calculateTotal = (ticketData: any, formattedFreeDate: any) => {
    
    let data = ticketData.reduce((acc: any, value: any) => {
      const ticketDate = value?.date;
      if (ticketDate !== formattedFreeDate) {
        return acc + (value.price * value.qty);
      }
      return acc;
    }, 0);
    return data
  };

  useEffect(() => {
    let total = calculateTotal(eventTicketData, formattedFreeDate);
    setTotal(total);
    console.log(total);
  }, [eventTicketData, formattedFreeDate]);

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined; // Initialize timer to undefined

    if (cooldown > 0) {
      timer = setInterval(() => {
        setCooldown((prev) => prev - 1);
        if (cooldown === 1) {
          setIsLoading(false);
          setIsMsgSent(false);
          console.log("test");
        }
      }, 1000);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [cooldown]);
  
  useEffect(() => {
    const DEFAULTCOMPANY = () => {
      dispatch(SetupDefaultcompany())
        .then((result: any) => {
          // Log the entire result to see the structure
          console.log(result, "Complete Result");

          // Check if the payload contains the setupdefaultcompany and data
          if (
            result.payload &&
            result.payload.setupdefaultcompany &&
            result.payload.setupdefaultcompany.data
          ) {
            const companyData = result.payload.setupdefaultcompany.data;
            console.log(companyData, "Company Data");

            // Log the public_settings data
            setOtpShow(false);
            if (companyData.public_settings) {
              companyData.public_settings.forEach((i: any) => {
                if (i.code === "otprequire") {
                  if (i.value === "no") {
                    setOtpShow(false);
                  } else {
                    setOtpShow(true);
                  }
                }
              });
            } else {
              console.log("No public_settings data found");
            }
          } else {
            console.log("No data found in the result payload");
          }
        })
        .catch((error: any) => {
          console.error(
            "Error occurred while setting up default company:",
            error
          );
        });
    };

    DEFAULTCOMPANY();
  }, [dispatch]);

  const handleCheckout = async (values: FormData) => {
    let formattedFreeDate = moment(freedate).format("D MMM");

    // Calculate discount amount
    const discountAmount = couponDiscount ? total * (couponDiscount / 100) : 0;

    // Calculate final total by excluding the price of the ticket on the free date
    const finalTotal =
      eventTicketData.reduce((acc: any, value: any) => {
        const ticketDate = value?.date
        if(ticketDate !== formattedFreeDate) {
          return acc + value.price * value.qty;
        }
        return acc;
      }, 0) - discountAmount;

    // const discountAmount = couponDiscount ? total * (couponDiscount / 100) : 0; // Calculate discount amount
    // const finalTotal = total - discountAmount;

    const formatDate = (dateStr: string) => {
      const [day, month] = dateStr.split(" ") as [string, 'Jan' | 'Feb' | 'Mar' | 'Apr' | 'May' | 'Jun' | 'Jul' | 'Aug' | 'Sep' | 'Oct' | 'Nov' | 'Dec'];
    
      const monthMapping: Record<'Jan' | 'Feb' | 'Mar' | 'Apr' | 'May' | 'Jun' | 'Jul' | 'Aug' | 'Sep' | 'Oct' | 'Nov' | 'Dec', string> = {
        Jan: '01',
        Feb: '02',
        Mar: '03',
        Apr: '04',
        May: '05',
        Jun: '06',
        Jul: '07',
        Aug: '08',
        Sep: '09',
        Oct: '10',
        Nov: '11',
        Dec: '12',
      };
    
      const year = new Date().getFullYear(); // Replace with actual year if needed
    
      return `${year}-${monthMapping[month]}-${String(day).padStart(2, '0')}`;
    };
    

    const payload = {
      ...values,
      phone: String(values.phone), // Convert phone to string
      event_id: Event.id,
      ticket_details: eventTicketData?.map((value: any) => {
        return {
          date: formatDate(value?.date), // Assuming this is static, you can replace with dynamic data if available
          title: value?.title,
          price: value?.price,
          qty: value?.qty,
        };
      }),
      total_price: finalTotal,
      otp: otp,
      coupon_code: couponCode,
    };
    setLoading(true);
    dispatch(CreateOrder(payload)).then((values: any) => {
      console.log(values.payload?.createOrderData?.order?.uuid, "values");
      if (values.payload?.createOrderData?.order?.uuid) {
        const url = `https://api.alabh.com/online-payment/${values.payload?.createOrderData?.order?.uuid}`;
        window.open(url, "_self");
        setLoading(false);
      } else {
        SweetToast.error(
          values.payload?.createOrderData?.messege ?? "Please Try Again Later"
        );
        // console.log(values.payload?.createOrderData?.messege , "error ticket")
        console.log(values?.payload, "error ticket");
        setLoading(false);
      }
    });
  };

  const handleApplyCoupon = async () => {
    if (!couponCode.trim()) {
      toast.error("Coupon code cannot be empty.");
      return;
    }
    setIscouponLoading(true); // Set loading to true
    // Simulate coupon code verification (replace this with actual API call)
    dispatch(
      ValidateCouponCode({ coupon_code: couponCode, event_id: Event.id })
    )
      .then((values: any) => {
        console.log(values.payload?.couponCodeData, "couponCodeData");
        if (values.payload?.couponCodeData?.success) {
          const discount = Math.floor(
            values.payload?.couponCodeData?.discount || 0
          );
          const freeDate = values.payload?.couponCodeData?.freeDate;
          console.log(freeDate, "freeDate");
          setCouponDiscount(discount);
          setCouponApplied(true);
          setCouponData(values.payload?.couponCodeData?.freeDate);
          setFreedate(values.payload?.couponCodeData?.freeDate);
          setIscouponLoading(false);
        } else {
          SweetToast.error(
            values.payload?.couponCodeData?.messege ?? "Please Try Again Later"
          );
          // console.log(values.payload?.couponCodeData?.messege , "error ticket")
          console.log(values?.payload, "error ticket");
          setIscouponLoading(false);
        }
      })
      .catch((error: any) => {
        setIscouponLoading(false);
        SweetToast.error("Coupon validation failed. Please try again.");
        console.error(error);
        return false; // Return false on error
      });
  };
  console.log(couponData, "couponData");

  const handleVerifyOTP = async (phone: any) => {
    setIsLoading(true); // Set loading to true

    dispatch(ValidateMobileNo({ phone: phone, otp: otp })).then(
      (values: any) => {
        console.log(values.payload?.mobileNoData?.success, "values");
        if (values.payload?.mobileNoData?.success) {
          setIsLoading(false);
          setIsMsgSent(false);
          setCooldown(0);
          setVerified(true);
          toast.success("OTP verified successfully!");
        } else {
          SweetToast.error(
            values.payload?.mobileNoData?.messege ?? "Please Try Again Later"
          );
          console.log(values?.payload, "error ticket");
          setIsLoading(false);
        }
      }
    );
  };
  const handleSendOTP = async (phone: any) => {
    setIsLoading(true); // Set loading to true
    // Simulate coupon code verification (replace this with actual API call)
    dispatch(SendMobileNo(phone)).then((values: any) => {
      console.log(values.payload?.sendMobileNoData?.success, "otp values");
      if (values.payload?.sendMobileNoData?.success) {
        toast.success("OTP has been sent successfully!");
      } else {
        toast.error("OTP has been not sent");
      }
      if (values.payload?.sendMobileNoData?.success) {
        setIsLoading(false);
        setIsMsgSent(true);
        setCooldown(60);
      } else {
        SweetToast.error(
          values.payload?.sendMobileNoData?.messege ?? "Please Try Again Later"
        );
        // console.log(values.payload?.createOrderData?.messege , "error ticket")
        console.log(values?.payload, "error ticket");
        setIsLoading(false);
      }
      console.log(values.payload?.createOrderData?.messege, "otp payload");
    });
  };

  if (!isVisible) return null;

  return (
    <div
      className={`fixed inset-0 z-50 flex items-center justify-end bg-black bg-opacity-70 transition-opacity duration-500 ease-in-out ${
        showSlide ? "opacity-100" : "opacity-0"
      }`}
    >
      <ToastContainer />
      <div
        className={`relative bg-white w-full max-w-4xl min-h-full px-2 shadow-lg transition-transform duration-500 ease-in-out transform hide-scrollbar ${
          showSlide ? "translate-x-0" : "translate-x-full"
        }`}
        style={{ maxHeight: "90vh", overflowY: "auto" }}
      >
        <div className="absolute top-4 right-4">
          <button
            onClick={handleCloseClick}
            className="text-gray-600 hover:text-gray-800"
          >
            <svg
              className="w-6 h-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className="items-center col-span-12 lg:col-span-6 p-4">
          <div className="p-3 pb-0 border-b">
            <h5 className="text-lg font-bold sm:text-xl">Ticket Information</h5>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              handleCheckout(values);
              setSubmitting(false);
            }}
          >
            {({ isSubmitting, isValid, values, handleChange }) => (
              <Form>
                <div className="p-3">
                  <div className="grid grid-cols-12 gap-4">
                    {/* Form Fields */}
                    <div className="col-span-12">
                      <h4 className="text-lg sm:text-xl text-[#6C757D]">
                        Contact Details:
                      </h4>
                    </div>
                    <div className="col-span-12 md:col-span-4">
                      <label className="block text-sm font-medium text-gray-700">
                        First Name <span className="text-red-500">*</span>
                      </label>
                      <Field
                        placeholder="Enter your first name here..."
                        name="first_name"
                        type="text"
                        className="block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                      />
                      <ErrorMessage
                        name="first_name"
                        component="p"
                        className="text-red-500 text-xs mt-1"
                      />
                    </div>
                    <div className="col-span-12 md:col-span-4">
                      <label className="block text-sm font-medium text-gray-700">
                        Last Name <span className="text-red-500">*</span>
                      </label>
                      <Field
                        placeholder="Enter your last name here..."
                        name="last_name"
                        type="text"
                        className="block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                      />
                      <ErrorMessage
                        name="last_name"
                        component="p"
                        className="text-red-500 text-xs mt-1"
                      />
                    </div>

                    <div className="col-span-12 md:col-span-4">
                      <label className="block text-sm font-medium text-gray-700">
                        Email 
                        {/* <span className="text-red-500">*</span> */}
                      </label>
                      <Field
                        placeholder="Enter your email here..."
                        name="email"
                        type="email"
                        className="block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                      />
                      
                      {/* <small className="text-xs text-gray-500">
                        Ticket will be received on this email-Id.
                      </small> */}
                    </div>

                    <div className="col-span-12 md:col-span-4">
                      <label className="block text-sm font-medium text-gray-700 ">
                        Phone Number <span className="text-red-500">*</span>
                      </label>
                      <Field
                        placeholder="Enter your phone here..."
                        name="phone"
                        type="text"
                        maxLength={10}
                        pattern="[0-9]*"
                        inputMode="numeric"
                        disabled={verified}
                        onChange={handleChange} // Using handleChange to update values in real-time
                        className=" w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                        onKeyPress={(e: any) => {
                          if (!/[0-9]/.test(e.key)) {
                            e.preventDefault(); // Prevent non-numeric input
                          }
                        }}
                      />

                      <small className="text-xs text-gray-500">
                        Ticket will be received on this Whatsapp Number.
                      </small>
                      <ErrorMessage
                        name="phone"
                        component="p"
                        className="text-red-500 text-xs mt-1"
                      />
                    </div>

                    {otpShow ? (
                      <>
                        <div className="col-span-12   md:col-span-8 space-x-4 md:space-x-2">
                          <p className=" col-md-4 mx-4 md:mx-2 flex">
                            OTP:
                            <span className="ml-3">
                              {verified ? (
                                <img
                                  src={varifiedSvg}
                                  alt=""
                                  width="20"
                                  height="20"
                                />
                              ) : (
                                <></>
                              )}
                            </span>{" "}
                          </p>
                          <Field
                            name="otp"
                            type="text"
                            disabled={verified}
                            value={otp}
                            onChange={(e: any) => setOTP(e.target.value)}
                            className="border-gray-300 rounded-md shadow-sm w-[250px] h-[34px] md:w-[150px] md:h-[34px] text-center"
                          />
                          {!verified && (
                            <>
                              <button
                                type="button"
                                className={`${
                                  cooldown > 0 ? "w-[140px]" : "w-[100px]"
                                } btn btn-secondary btn-sm justify-center items-center mt-2 md:mt-0`}
                                disabled={isLoading || cooldown > 0}
                                onClick={() => handleSendOTP(values.phone)}
                              >
                                {isLoading ? (
                                  <div className="flex items-center">
                                    <span className="loader spinner-border spinner-border-sm mr-0"></span>
                                    {isMsgSent ? "Verifying..." : "Sending..."}
                                  </div>
                                ) : cooldown > 0 ? (
                                  `Resend in ${cooldown}s`
                                ) : isMsgSent ? (
                                  "Verify"
                                ) : (
                                  "Send"
                                )}
                              </button>
                              {isMsgSent && (
                                <button
                                  type="button"
                                  className={`w-[100px] btn btn-secondary btn-sm justify-center items-center mt-2 md:mt-0`}
                                  disabled={isLoading} // Disable button during loading
                                  onClick={() => handleVerifyOTP(values.phone)}
                                >
                                  {isLoading ? (
                                    <div className="flex items-center">
                                      <span className="loader spinner-border spinner-border-sm mr-0"></span>{" "}
                                      {/* Loading spinner */}
                                      Verifying...
                                    </div>
                                  ) : (
                                    "Verify"
                                  )}
                                </button>
                              )}
                            </>
                          )}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    <div className="col-span-12">
                      <div className="grid items-center">
                        <div className="flex">
                          <Field
                            name="checkBox"
                            type="checkbox"
                            id="default-checkbox"
                            className="h-4 w-4 text-indigo-600 border-gray-300 focus:ring-indigo-500 rounded"
                          />
                          <label
                            htmlFor="default-checkbox"
                            className="ml-2 text-xs lg:text-sm text-gray-700"
                          >
                            Register myself with Labh Creation
                          </label>
                        </div>
                        <ErrorMessage
                          name="checkBox"
                          component="p"
                          className="text-red-500 text-xs mt-1"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="border-t py-3 grid grid-cols-12 gap-4">
                    <div className="col-span-12 mb-3 lg:mb-0 py-1">
                      <h6 className="font-bold text-[#17A2B8] mb-3">
                        Tickets:
                      </h6>
                      {eventTicketData?.map((value: any) => {
                        const formattedFreeDate = couponApplied
                          ? moment(freedate).format("D MMM")
                          : null;
                        const ticketDate = moment(value?.date).format("D MMM"); // Format ticket date

                        // Skip rendering if the date matches the free date
                        if (ticketDate === formattedFreeDate) {
                          return null; // Do not render this ticket
                        }
                        return (
                          <>
                            <div>
                              <p className="mb-0 text-xs text-gray-800 font-bold">
                                {value?.date}
                              </p>
                            </div>
                            <div className="flex justify-between items-center gap-1 mb-3">
                              <div>
                                <p className="text-[#6C757D] capitalize text-sm font-bold">
                                  {value?.title}
                                </p>
                                <p className="mb-0 text-xs text-gray-800 font-bold">
                                  {value?.qty} Ticket
                                </p>
                              </div>
                              <div>
                                <p className="mb-0">
                                  ₹{value.price * value?.qty}
                                </p>
                              </div>
                            </div>
                            <hr className="py-2 border-[#17A2B8]" />
                          </>
                        );
                      })}

                      <div className="flex justify-between items-center mb-3">
                        <p className="text-gray-800 mb-0">
                          <span className="font-bold">Total Amount:</span>
                        </p>
                        <p className="mb-0">₹{total}</p>
                      </div>

                      <div className="flex justify-between items-center mb-3">
                        <p className="text-gray-800 mb-0">
                          <span className="font-bold">Coupon Code:</span>
                        </p>

                        <div className="flex items-center space-x-2">
                          {!couponApplied ? (
                            <>
                              <Field
                                name="coupon_code"
                                type="text"
                                value={couponCode}
                                onChange={(e: any) =>
                                  setCouponCode(e.target.value)
                                }
                                className="border-gray-300 rounded-md shadow-sm w-[100px] h-[34px] md:w-[150px] md:h-[34px] text-center"
                              />

                              <button
                                type="button"
                                className={`w-[50px] md:w-[100px] btn btn-secondary btn-sm justify-center items-center`}
                                disabled={isCouponLoading} // Disable button during loading
                                onClick={handleApplyCoupon}
                              >
                                {isCouponLoading ? (
                                  <div className="flex items-center">
                                    <span className="loader spinner-border spinner-border-sm mr-2"></span>
                                    {/* Loading spinner */}
                                    Verifying...
                                  </div>
                                ) : (
                                  "Apply"
                                )}
                              </button>
                            </>
                          ) : (
                            // Message when coupon is applied
                            <p className="text-green-500 font-normal md:font-bold flex text-sm md:text-base">
                              {couponApplied && (
                                <p>{couponDiscount}% discount applied!</p>
                              )}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="flex justify-between items-center mb-3">
                        <p className="text-gray-800 mb-0">
                          <span className="font-bold">Net Amount:</span>
                        </p>
                        <p className="font-bold mb-0">
                          {couponApplied ? (
                            <>
                              <span>
                                ₹{total - total * (couponDiscount / 100)}
                              </span>
                            </>
                          ) : (
                            <span>₹{total}</span>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="sticky bottom-0 left-0 w-full p-2 border-t bg-white">
                    <p className="text-red-600 text-sm mb-0">
                      (*) All fields are required
                    </p>
                    <div className="flex justify-between items-center gap-3">
                      <div>
                        <h4 className="font-bold text-gray-900 mb-0">
                          Amount Payable:{" "}
                          <span className="text-green-600">
                            {couponApplied ? (
                              <>
                                <span>
                                  ₹{total - total * (couponDiscount / 100)}
                                </span>
                              </>
                            ) : (
                              <span>₹{total}</span>
                            )}
                          </span>
                        </h4>
                      </div>
                      <div>
                        <button
                          type="submit"
                          className={`w-[150px] btn btn-primary flex justify-center items-center ${
                            loading ? "cursor-not-allowed opacity-75" : ""
                          }`}
                          disabled={loading || (!verified && otpShow)}
                        >
                          {loading ? (
                            <p>
                              <svg
                                className="animate-spin h-5 w-5 text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  className="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  strokeWidth="4"
                                ></circle>
                                <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"
                                ></path>
                                Proceess..
                              </svg>
                            </p>
                          ) : (
                            "Proceed to Pay"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default TicketSlider;
