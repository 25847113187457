//ProductDetailPage.tsx
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// import { EventData } from "../../data/data";
// import LabhLogo from "../../Assets/Allabh-Logo.png";
import moment from "moment";
import RakeshBhai from "../../Assets/Rakesh_Amin_Photo.jpg";
import facebook from "../../Assets/svg/Facebook.svg";
// import Youtube from "../../Assets/youtube.jpg";
import LabhaFlower from "../../Assets/labh-logo/labh_creation_flower.jpg";
import Labhacreation from "../../Assets/labh-logo/Labh Creation Home Page Name.png";
import whatsapp from "../../Assets/svg/whatsapp.svg";
import instagram from "../../Assets/instagram-logo.jpg";
import Location from "../../Assets/svg/location.svg";
import Ekalocation from "../../Assets/Eka-ras-garba-Location-img.jpg";
import DateImg from "../../Assets/Collection/DateImg.png";
import TimeImg from "../../Assets/Time.png";
import AccordionInfoEvent from "../../containers/ProductDetailPage/AccordionInfoEvent";
import FadeInUpOnScroll from "../../components/Animation/FadeInUpOnScroll";
import SectionNewsLetter from "../../containers/PageHome/SectionNewsLetter";
import TicketSlider from "./TicketSlider";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Tabs from "./TickitSchedule";
import { GetEventDetail } from "containers/store";

const ProductDetailPage = () => {
  const dispatch = useDispatch();
  const [showSlide, setShowSlide] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const { id } = useParams<{ id: string }>();
  const [eventTicketData, setEventTicketData] = useState([]);

  const GetEventDetailData = useSelector(
    (state: any) => state.persistedReducer.ContainerDataEvent.getEventDetailShow
  );

  console.log(GetEventDetailData, "GetEventDetailData");

  useEffect(() => {
    if (isVisible) {
      setShowSlide(true);
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      // Delay hiding the component until the fade-out transition is complete
      const timer = setTimeout(() => {
        setShowSlide(false);
        document.body.style.overflow = "auto"; // Re-enable scrolling
      }, 500); // Match the fade-out transition duration

      return () => clearTimeout(timer);
    }
  }, [isVisible]);

  const handleNextClick = () => {
    setIsVisible(true); // Start showing the slide
  };

  const handleCloseClick = () => {
    setIsVisible(false); // Start hiding the slide
  };
  useEffect(() => {
    dispatch(GetEventDetail(id));
  }, [dispatch, id]);

  const Start_date = moment(GetEventDetailData?.date).format("DD-MM-YYYY");
  const End_date = moment(GetEventDetailData?.end_date).format("DD-MM-YYYY");

  const handleClickOnNext = (filteredData: any) => {
    setEventTicketData(filteredData);
    handleNextClick();
  };

  useEffect(() => {
    window.scrollTo({
      top: 200,
      behavior: 'smooth', // Optional: makes the scroll smooth
    });
  }, []); 

  return (
    <>
      <div className="container grid grid-cols-12 gap-4 bg-[#f1f1f1] mt-3 ">
        <div className="col-span-12 lg:col-span-8 p-1 lg:p-4">
          <FadeInUpOnScroll defaultClassName="animated" className="fadeInUp">
            <div className="max-w-full rounded overflow-hidden bg-white shadow-lg">
              <img
                className="w-full h-auto md:h-60 lg:h-80 object-cover"
                src={GetEventDetailData?.image}
                alt="EventImage"
              />
              {/* <div className="absolute top-1  left-2 bg-red-500 text-white text-xs md:text-sm font-bold px-3 py-1 rounded-full shadow-md">
                100 ₹ Discount
              </div> */}
              <div className="px-3 py-3">
                <div className="font-semibold text-base sm:text-xl md:text-2xl lg:text-2xl mb-2">
                  {GetEventDetailData?.name}
                </div>
                <p className="px-2 text-xs sm:text-sm md:text-base lg:text-base items-center flex">
                  <span className="text-red-500 flex items-center">
                    <img
                      src={Location}
                      alt="Location Icon"
                      className="w-5 h-5 sm:w-5 sm:h-5 md:w-5 md:h-5 lg:w-5 lg:h-5"
                    />
                  </span>
                  <a href="https://www.google.com/maps/place/EKA+Club/@23.0108347,72.5966213,17z/data=!3m1!4b1!4m6!3m5!1s0x395e85cedfe1ecbb:0xe70b832deddbed49!8m2!3d23.0108347!4d72.5991962!16s%2Fg%2F11gds3vj_4?entry=ttu&g_ep=EgoyMDI0MDgyMC4xIKXMDSoASAFQAw%3D%3D">
                    <p className="text-red-500 mx-3">
                      {GetEventDetailData?.venue}
                    </p>
                  </a>
                </p>
              </div>
              <div className="flex justify-start items-center flex-wrap gap-1">
                <p className="px-5 flex items-start italic font-bold text-xs sm:text-sm md:text-base lg:text-lg gap-1">
                  <img
                    src={DateImg}
                    alt=""
                    className="w-3 h-3 sm:w-5 sm:h-5 md:w-5 md:h-5 lg:w-5 lg:h-5"
                  />
                  {/* 29th Jul 2024&nbsp;to&nbsp;6th Aug 2024 */}
                  <span className="text-gray-700">
                    {Start_date} to {End_date}
                  </span>
                </p>
                <p className="flex items-center px-5 md:px-8 lg:px-8 italic font-bold mr-3 text-xs sm:text-sm md:text-base lg:text-lg gap-1">
                  <img
                    src={TimeImg}
                    alt=""
                    className="w-3 h-3 sm:w-5 sm:h-5 md:w-5 md:h-5 lg:w-5 lg:h-5"
                  />
                  {/* 11:00 AM IST */}
                  <span className="text-gray-700">
                    {GetEventDetailData?.time} PM
                  </span>
                </p>
              </div>
              {/* {GetEventDetailData?.rescheduled_date &&
                GetEventDetailData?.rescheduled_reason && (
                  <p className="mt-1 bg-yellow-100 inline-block lg:ml-5 md:ml-5 sm:ml-1 rounded p-1 text-xs sm:text-sm md:text-sm lg:text-base">
                    📣 Rescheduled {GetEventDetailData?.rescheduled_date} |
                    Reason: {GetEventDetailData?.rescheduled_reason}
                  </p>
                )} */}

              <div className="flex lg:justify-between md:justify-between sm:justify-center justify-between items-center gap-10 p-1">
                <div>
                  <p className="px-5 p-1 text-xs sm:text-sm md:text-base lg:text-lg">
                    Share Event:
                  </p>
                </div>
                <div className="flex justify-center items-center pr-10 gap-2">
                  <span className="cursor-pointer">
                    <a href="https://www.facebook.com/labhcreation.in">
                      <img
                        src={facebook}
                        alt="Facebook"
                        className="w-5 h-5 sm:w-6 sm:h-6 md:w-7 md:h-7 lg:w-8 lg:h-8"
                      />
                    </a>
                  </span>
                  <span className="cursor-pointer">
                    <a href="https://wa.me/919979411999">
                      <img
                        src={whatsapp}
                        alt="WhatsApp"
                        className="w-5 h-5 sm:w-6 sm:h-6 md:w-7 md:h-7 lg:w-8 lg:h-8"
                      />
                    </a>
                  </span>
                  <span className="cursor-pointer">
                    <a href="https://www.instagram.com/labhcreation.in/">
                      <img
                        src={instagram}
                        alt="instagram"
                        className="w-5 h-5 sm:w-6 sm:h-6 md:w-7 md:h-7 lg:w-8 lg:h-8"
                      />
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </FadeInUpOnScroll>
          <div
            id="tickets"
            className="card bg-white shadow rounded-lg p-2 mt-3"
          >
            <FadeInUpOnScroll defaultClassName="animated" className="fadeInUp">
              <div className="flex flex-col md:flex-row md:justify-between items-center px-3 py-1 gap-2 border-b-2">
                <h5 className="text-lg font-semibold">Ticket Information:</h5>
              </div>
            </FadeInUpOnScroll>
            <div>
              <Tabs
                onSubmit={handleClickOnNext}
                GetEventDetailData={GetEventDetailData}
              />
            </div>
          </div>
          {/* Event-Detail start*/}
          <div className="bg-white shadow-lg rounded-lg mb-4 mt-3">
            <FadeInUpOnScroll defaultClassName="animated" className="fadeInUp">
              <div className="flex flex-col md:flex-row md:justify-center items-center py-2">
                <h5 className="text-xl font-semibold">Event Details:</h5>
              </div>
              <div className="px-4 py-2">
                <p className="text-lg font-semibold text-gray-800 mb-2">
                  <span className="text-[#666]">Event Name:</span>{" "}
                  {GetEventDetailData?.name}
                </p>
                <Link to="/orchesta">
                  <p className="text-red-600 p-0 underline">
                    Orchesta List 03 oct to 11 oct 2024...
                  </p>
                </Link>
                <div className="event-description-html text-gray-600 text-base leading-relaxed mt-1">
                  <p className="mb-2 text-justify text-sm">
                    Join us for {GetEventDetailData?.name}, a celebration of
                    tradition and festivity. Experience new performers every day
                    on our spacious grounds with lush green grass. Enjoy
                    traditional Ras-Garba, ample vehicle parking, gunmen and
                    bouncers for security, selfie points, personal photography,
                    display areas, a food court, and VIP seating.
                  </p>
                  <p>
                    <strong>Venue :</strong>
                  </p>
                  <a href="https://www.google.com/maps/place/EKA+Club/@23.0108347,72.5966213,17z/data=!3m1!4b1!4m6!3m5!1s0x395e85cedfe1ecbb:0xe70b832deddbed49!8m2!3d23.0108347!4d72.5991962!16s%2Fg%2F11gds3vj_4?entry=ttu&g_ep=EgoyMDI0MDgyMC4xIKXMDSoASAFQAw%3D%3D">
                    <p className="mb-2 text-sm">{GetEventDetailData?.venue}</p>
                  </a>
                  <p className="mb-2">
                    <strong>Date &amp; Time:</strong>
                  </p>
                  <p className="mb-2">
                    {Start_date} to {End_date}, {GetEventDetailData?.time}
                  </p>
                  <p className="mb-2"></p>
                  <p>
                    To inquire, email us at{" "}
                    <a
                      href="mailto:marketing@kidtownfair.com"
                      className="text-blue-600 underline"
                    >
                      labhcreation@gmail.com
                    </a>
                  </p>
                </div>
              </div>
            </FadeInUpOnScroll>
          </div>

          {/* Event-Detail  end*/}

          {/* Accordion start */}
          <FadeInUpOnScroll defaultClassName="animated" className="fadeInUp">
            <AccordionInfoEvent />
          </FadeInUpOnScroll>
          {/* Accordion end */}
        </div>

        <div className="col-span-12 lg:col-span-4  bg-[#f1f1f1] mb-2 ">
          {/* Location start */}
          <div className="relative flex flex-col lg:mt-4  text-gray-700 bg-white shadow-md bg-clip-border rounded-xl w-100">
            <div className="p-6">
              <h5 className="text-lg font-black">Venue :</h5>
              <div className="flex justify-center items-start mt-1">
                <img src={Location} alt="Location" />
                <a href="https://www.google.com/maps/place/EKA+Club/@23.0108347,72.5966213,17z/data=!3m1!4b1!4m6!3m5!1s0x395e85cedfe1ecbb:0xe70b832deddbed49!8m2!3d23.0108347!4d72.5991962!16s%2Fg%2F11gds3vj_4?entry=ttu&g_ep=EgoyMDI0MDgyMC4xIKXMDSoASAFQAw%3D%3D">
                  <p className="block  font-sans text-xs sm:text-sm md:text-base lg:text-lg antialiased font-semibold text-red-500">
                    {GetEventDetailData?.venue}
                  </p>
                </a>
              </div>
            </div>
            <div className="p-6 pt-0"></div>
            <div className="relative h-56 mx-4 -mt-6 overflow-hidden text-white shadow-lg bg-clip-border rounded-xl bg-blue-gray-500 shadow-blue-gray-500/40 mb-4">
              <img src={Ekalocation} alt="location" />
            </div>
          </div>
          {/* Location end */}
          <div className="p-2 mt-4 card bg-white shadow rounded">
            <div className="bg-white p-2  mx-auto relative overflow-hidden">
              <div className="flex justify-center md:justify-start gap-2 items-center">
                <div className="relative w-12 h-12 md:w-20 md:h-20 rounded-full overflow-hidden border-4 border-gray-300">
                  <img
                    src={RakeshBhai}
                    alt="Organizer"
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="md:ml-4 text-center md:text-left">
                  <h2
                    className="text-base md:text-2xl font-bold text-gray-800"
                    style={{ fontFamily: "Candara" }}
                  >
                    Rakesh Amin
                  </h2>
                </div>
              </div>

              {/* Organized by section */}
              <div className="mt-3 text-center">
                <div className="flex justify-center items-center space-x-4 mt-1">
                  <img
                    src={LabhaFlower}
                    alt="Labh Creation Logo"
                    className="w-12 h-12 md:w-[70px] md:h-[70px] mt-6"
                  />
                  <div>
                    <p className="text-gray-600 font-semibold text-xs md:text-sm mb-4 text-center">
                      Organized by:
                    </p>
                    <div className="flex items-center space-x-1 space-y-2 mb-2">
                      <img
                        src={Labhacreation}
                        alt="Labhacreation"
                        className="w-[150px] h-[24px] md:w-[230px] md:h-[28px]"
                      />
                    </div>
                    <p className="text-xs md:text-sm text-start md:text-center text-gray-600 mt-1">
                      Design | Decor | Event Planner
                    </p>
                    <a href="https://labhcreation.com/">
                      <p className="font-semibold text-sm  mt-1">
                        www.labhcreation.com
                      </p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white mt-4 shadow-md rounded-lg p-4">
            <div className="text-xl font-semibold mb-2">
              Event Social Links:
            </div>
            <div className="pb-2">
              <div className="flex flex-wrap justify-between items-center gap-3 mt-1">
                <a
                  href="https://www.facebook.com/labhcreation.in"
                  className="cursor-pointer min-w-[120px]"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="flex items-center gap-2">
                    <img src={facebook} alt="" />
                    <p className="text-secondary mt-1 mb-0">Facebook</p>
                  </div>
                </a>

                <a
                  href="https://www.instagram.com/labhcreation.in/"
                  className="cursor-pointer min-w-[120px]"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="flex items-center gap-2">
                    <img src={instagram} alt="" className="w-6 h-6" />
                    <p className="text-secondary mt-1 mb-0">Instagram</p>
                  </div>
                </a>

                {/* <a
                  href="/"
                  className="cursor-pointer min-w-[120px]"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="flex items-center gap-2">
                    <img
                      src={Youtube}
                      alt=""
                      className="w-8 h-8 border rounded-xl"
                    />
                    <p className="text-secondary mt-1 mb-0">Youtube</p>
                  </div>
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Slide Transition and Overlay */}
       {isVisible? 
       (        
      <TicketSlider
        isVisible={isVisible}
        showSlide={showSlide}
        eventTicketData={eventTicketData}
        Event={GetEventDetailData}
        handleCloseClick={handleCloseClick}
      />
       ):(<></>)
     }    
      <SectionNewsLetter />
      <div />
    </>
  );
};

export default ProductDetailPage;
