import { SocialType } from "shared/SocialsShare/SocialsShare";
import React, { FC } from "react";
import facebook from "images/socials/facebook.svg";
import Whatsapp from "../../Assets/svg/whatsapp.svg"
export interface SocialsListProps {
  className?: string;
  itemClass?: string;
  socials?: SocialType[];
}

const socialsDemo: SocialType[] = [
  {
    name: "Facebook",
    icon: facebook,
    // href: "https://www.facebook.com/people/MEET-Solution-Incorporation/100057427150199/?",
    href:"https://www.facebook.com/labhcreation.in"
  },
  {
    name: "Instagram",
    icon: "https://i.pinimg.com/736x/0a/1e/6f/0a1e6f9872388e41f03f6886654a8235.jpg",
    href:"https://www.instagram.com/labhcreation.in/"
    // href: "https://www.instagram.com/meet_solution_incorporation_?igsh=MW51Y2hhZDdxMjBoZA%3D%3D",
  },
  {
    name: "Whatsapp",
    icon: Whatsapp,
    href:"https://wa.me/919979411999"
  },
];

const SocialsList: FC<SocialsListProps> = ({
  className = "",
  itemClass = "block w-6 h-6",
  socials = socialsDemo,
}) => {
  return (
    <nav
      className={`nc-SocialsList flex space-x-2.5 text-2xl text-neutral-6000 dark:text-neutral-300 ${className}`}
      data-nc-id="SocialsList"
    >
      {socials.map((item, i) => (
        <a
          key={i}
          className={`${itemClass}`}
          href={item.href}
          target="_blank"
          rel="noopener noreferrer"
          title={item.name}
        >
          <img src={item.icon} alt="" />
        </a>
      ))}
    </nav>
  );
};

export default SocialsList;
