export const API_ENDPOINTS = {
  register: "register",
  login: "login",
  cms: "website/cms",
  Category: "get-category",
  PopularProduct: "get-popular-products",
  // itemMaster: "get-popular-products",
  itemMasterByID: "get-product",
  itemMaster: "get-products",
  Banner: "/herosection/get-all",
  Categories: "/category/get-all",
  ProductByCategory: "/getProductByCategory",
  ProductBySlug: "/getProductBySlug",
  PageBySlug: "/getPageBySlug",
  Settings: "/getSettingData",
  Event: "hr/events",
  EventDetail: "hr/events/show",
  MobileOtp: "/send-mobile-otp",
  VerifyOtp: "/verify-mobile-otp",
  forgotPass: "forgot-password",
  garbaclass: "event/idcards/list",
  idCardById: "event/idcards",
  garbaFormAdd: "event/idcards",
  icardPass: "idcards/online-idcard", //9 days i-card
  garbaClassShow: "GarbaClass/show",
  createOrder: "orders/create",
  orderShow: "orders/show",
  orderShowUUID : "orders/show",
  getsplitticket: "tickets/get-ticket-details",
  uploadFile: "file/upload",
  validateCouponCode: "coupon-code/verify",
  validateMobileNo: "sms/verify",
  sendMobileNo: "sms/send",
  defalutCompany:"setup/default-company",
  splitTicket:"tickets/split"
};
