import React from "react";
import Breadcrumb from "../components/Breadcrumb"; 
import GarbaClassIcardForm from "./GarbaClassIcardForm";

interface FormValues {
  name: string;
  mobile: string;
  email: string;
  city: string;
  qty: number;
  photo: any;
  aadhar_card: any;
}

const GarbaClassForm: React.FC = () => {
  
  const addInitialValues: FormValues = {
    name: "",
    mobile: "",
    email: "",
    city: "Ahmedabad",
    qty: 1,
    aadhar_card: null,
    photo: null,
  };

  return (
    <>
      <div className="container mt-2">
        <div>
          <Breadcrumb
            items={[
              { label: "Back", path: "/garba-class-form-list" },
              { label: "Garba-class-Form", path: "/garba-class-form-add" },
              { label: "", path: "#" },
              // Current page, no link
            ]}
          />
        </div>
        <div className="mt-5 max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg mb-2">
          <h2 className="text-2xl font-semibold mb-6 text-center text-blue-600">
            Garba Class Form
          </h2>
          <GarbaClassIcardForm initialValues={addInitialValues} isEdit={false} />
        </div>
      </div>
    </>
  );
};

export default GarbaClassForm;
