import React, { useEffect, useState } from "react";
import {
  Splitticket,
  ValidateMobileNo,
  SendMobileNo,
  SplitTicketForm,
  SetupDefaultcompany,
} from "containers/store";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { SweetToast } from "utils/sweetAlert";

const SplitForm: React.FC = () => {
  const dispatch = useDispatch();
  const { uuid } = useParams();
  const [uuiddata, setUuiddata] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [isMsgSent, setIsMsgSent] = useState(false);
  const [isSplit, setIsSplit] = useState(false);
  const [otp, setOTP] = useState("");
  const [isLoadingResendotp, setisLoadingResendOTP] = useState(false);
  const [verified, setVerified] = useState(false);
  const [isOtpDisplay, setisOtpDisplay] = useState(false);
  const [otpShow, setOtpShow] = useState(false);
  const [isSubmit, setisSubmit] = useState(false);
  const [cooldown, setCooldown] = useState(0);

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      name: "",
      mobile: "",
      // email: "",
      // city: "",
      quantity: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Name is required")
        .min(2, "Name must be at least 2 characters"),
      mobile: Yup.number()
        .required("Mobile is required")
        .typeError("Mobile must be a number")
        .test("len", "Mobile must be a 10 digit number", (val) => {
          return val !== undefined && val !== null && String(val).length === 10;
        }),
      // email: Yup.string()
      //   .required("Email is required")
      //   .email("Invalid email format"),
      // city: Yup.string()
      //   .required("City is required")
      //   .min(2, "City must be at least 2 characters"),
      quantity: Yup.number()
        .required("Quantity is required")
        .min(1, "Quantity must be at least 1"),
    }),
    onSubmit: (values) => {
      console.log("Payload on Submit:", values);
      if (otpShow) {
        handleSendOTP(uuiddata.mobile);
        console.log("OTP sent to:", uuiddata.mobile);
      }
      // handleSendOTP(uuiddata.mobile);
      console.log(uuiddata.mobile, "uuid mobile ");
      setisOtpDisplay(true);
      setisSubmit(true);
    },
  });

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === "") {
      formik.setFieldValue("quantity", "");
      return;
    }
    const numericValue = Number(value);
    const remainingQty = Math.max(
      0,
      (uuiddata?.qty ?? 0) - (uuiddata?.scanned_qty ?? 0)
    );
    if (numericValue > 0 && numericValue <= remainingQty) {
      formik.setFieldValue("quantity", numericValue);
    } else {
      toast.error(`Quantity cannot exceed ${remainingQty}`);
    }
  };

  //   dispatch(SetupDefaultcompany())
  //     .then((result: any) => {
  //       console.log(result, "Complete Result");

  //       if (
  //         result.payload &&
  //         result.payload.setupdefaultcompany &&
  //         result.payload.setupdefaultcompany.data
  //       ) {
  //         const companyData = result.payload.setupdefaultcompany.data;
  //         console.log(companyData, "Company Data");

  //         setOtpShow(false);
  //         if (companyData.public_settings) {
  //           companyData.public_settings.forEach((i: any) => {
  //             if (i.code === "otprequire") {
  //               if (i.value === "no") {
  //                 setOtpShow(false);
  //               } else {
  //                 setOtpShow(true);
  //               }
  //             }
  //           });
  //         } else {
  //           console.log("No public_settings data found");
  //         }
  //       } else {
  //         console.log("No data found in the result payload");
  //       }
  //     })
  //     .catch((error: any) => {
  //       console.error(
  //         "Error occurred while setting up default company:",
  //         error
  //       );
  //     });
  // };

  useEffect(() => {
    const DEFAULTCOMPANY = () => {
      dispatch(SetupDefaultcompany())
        .then((result: any) => {
          console.log(result, "Complete Result");

          if (
            result.payload &&
            result.payload.setupdefaultcompany &&
            result.payload.setupdefaultcompany.data
          ) {
            const companyData = result.payload.setupdefaultcompany.data;
            console.log(companyData, "Company Data");

            setOtpShow(false);
            if (companyData.public_settings) {
              companyData.public_settings.forEach((i: any) => {
                setOtpShow(i.value !== "no");
              });
            } else {
              console.log("No public_settings data found");
            }
          } else {
            console.log("No data found in the result payload");
          }
        })
        .catch((error: any) => {
          console.error(
            "Error occurred while setting up default company:",
            error
          );
        });
    };

    DEFAULTCOMPANY();
  }, [dispatch]);

  useEffect(() => {
    if (uuid) {
      dispatch(Splitticket(uuid)).then((res: any) => {
        if (res.meta.requestStatus === "fulfilled") {
          if (res.payload.getsplitticket?.success) {
            const fetchedData = res.payload.getsplitticket.data;
            setUuiddata(fetchedData);
            console.log(fetchedData, "Fetching Data uuiddd");
          } else {
            // console.log(res.payload.getsplitticket , 'fff')
            toast.error(res.payload.getsplitticket?.message ?? " ");
          }
        } else {
          console.log(res.error, "Error Fetching Data");
        }
      });
    }
  }, [uuid, dispatch]);

  // console.log(uuiddata,"uuiddddddddd")

  const handleSendOTP = async (mobile: string) => {
    // setIsLoading(true);
    setisLoadingResendOTP(true);
    dispatch(SendMobileNo(mobile)).then((values: any) => {
      if (values.payload?.sendMobileNoData?.success) {
        if (otpShow) {
          toast.success("OTP has been sent successfully!");
          setIsLoading(false);
          setisLoadingResendOTP(false);
          setIsMsgSent(true);
          setCooldown(60);
        }
        // setIsMsgSent(true);
      } else {
        SweetToast.error(
          values.payload?.sendMobileNoData?.message ?? "Please Try Again Later"
        );
      }
      // setIsLoading(false);
    });
  };

  const handleVerifyOTP = async () => {
    setIsLoading(true);
    dispatch(ValidateMobileNo({ phone: uuiddata.mobile, otp })).then(
      (values: any) => {
        if (values.payload?.mobileNoData?.success) {
          setVerified(true);
          setIsMsgSent(false);
          setCooldown(0);
          toast.success("OTP verified successfully!");
        } else {
          SweetToast.error(
            values.payload?.mobileNoData?.message ?? "Please Try Again Later"
          );
        }
        setIsLoading(false);
      }
    );
  };

  const handleNextStep = () => {
    const { quantity, ...rest } = formik.values;
    const payload = {
      ...rest,
      qty: formik.values.quantity,
      uuid,
    };
    console.log("Next Step Payload:", payload);
    setIsSplit(true);
    dispatch(SplitTicketForm(payload)).then((val: any) => {
      console.log(val?.payload?.splitTicketdata, "splitTicketdata");
      if (val?.payload?.splitTicketdata?.success) {
        toast.success(
          val?.payload?.splitTicketdata?.message ?? "Split Ticket Successfully"
        );
          navigate(`/split/${uuid}`);
          window.location.reload();
        // navigate(`/split/${uuid}`);
        // window.location.reload();
      } else {
        SweetToast.error(
          val.payload?.message
            ? val.payload?.message
            : val.payload?.splitTicketdata?.message
            ? val.payload?.splitTicketdata?.message
            : "Please try Again"
        );
      }
      setIsSplit(false);
    });
  };

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined; // Initialize timer to undefined

    if (cooldown > 0) {
      timer = setInterval(() => {
        setCooldown((prev) => prev - 1);
        if (cooldown === 1) {
          setIsLoading(false);
          setIsMsgSent(false);
          console.log("test");
        }
      }, 1000);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [cooldown]);

  return (
    <div className="flex justify-center items-center p-4">
      <div>
        <form
          onSubmit={formik.handleSubmit}
          className="bg-white p-6 rounded-lg shadow-md w-full max-w-md"
        >
          <h2 className="text-2xl md:text-3xl font-extrabold mb-2 text-center text-blue-600 shadow-md p-2 bg-white rounded-md border border-gray-200">
            Split Your Ticket
          </h2>
          <p className="mb-5 text-center bg-gray-100 p-1 rounded-lg shadow-md">
            <span className="text-gray-600 font-normal">
              Available Ticket Quantity:{" "}
            </span>
            <span className="text-blue-600 font-bold text-base">
              {Math.max(0, (uuiddata?.qty ?? 0) - (uuiddata?.scanned_qty ?? 0))}
            </span>
          </p>

          {/* Name Input */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`w-full px-3 py-2 border ${
                formik.touched.name && formik.errors.name
                  ? "border-red-500"
                  : "border-gray-300"
              } rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500`}
            />
            {formik.touched.name && formik.errors.name && (
              <div className="text-red-500 text-sm">{formik.errors.name}</div>
            )}
          </div>

          {/* Mobile Input */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Mobile
            </label>
            <input
              type="number"
              id="mobile"
              name="mobile"
              value={formik.values.mobile}
              onChange={(e) => {
                const value = e.target.value;
                if (value.length <= 10) {
                  formik.setFieldValue("mobile", value);
                }
              }}
              onBlur={formik.handleBlur}
              className={`w-full px-3 py-2 border ${
                formik.touched.mobile && formik.errors.mobile
                  ? "border-red-500"
                  : "border-gray-300"
              } rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500`}
            />
            {formik.touched.mobile && formik.errors.mobile && (
              <div className="text-red-500 text-sm">{formik.errors.mobile}</div>
            )}
          </div>

          {otpShow && isOtpDisplay && (
            // <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-md mt-4">
            <div>
              {/* <h2 className="text-xl font-semibold mb-4 text-center">
              Enter OTP
            </h2> */}
              <div className="grid grid-cols-12 gap-2">
                <div className="col-span-8">
                  <input
                    type="number"
                    placeholder="Enter your OTP"
                    className="border border-gray-300 rounded-lg p-2 w-full mb-4"
                    value={otp}
                    disabled={verified}
                    onChange={(e) => setOTP(e.target.value)}
                  />
                </div>
                <div className="col-span-4">
                  <button
                    className={`${
                      verified
                        ? "bg-slate-200 text-gray-500"
                        : "bg-green-500 text-white"
                    } font-semibold rounded-lg p-2 w-full hover:bg-green-600`}
                    disabled={isLoading || verified}
                    onClick={handleVerifyOTP}
                  >
                    {isLoading ? "Verifying..." : "Verify"}
                  </button>
                </div>
              </div>

              {/* <div className=""> */}

              {cooldown < 1 ? (
                <>
                  {isLoadingResendotp ? (
                    <>
                      {" "}
                      <p className="text-green-400 text-right mr-2 mb-3 cursor-pointer">
                        Sending
                      </p>{" "}
                    </>
                  ) : (
                    <>
                      {" "}
                      <p
                        className="text-green-400 text-right mr-2 mb-3 cursor-pointer"
                        onClick={() => handleSendOTP(uuiddata.mobile)}
                      >
                        Resend otp
                      </p>
                    </>
                  )}
                </>
              ) : (
                <p className="text-red-400 text-right">
                  {otpShow && cooldown > 0 && `Resend in ${cooldown}s`}
                </p>
              )}

              {/* <button
                  className={`${
                    verified
                      ? "bg-slate-200 text-gray-500"
                      : "bg-green-500 text-white"
                  } font-semibold rounded-lg p-2 w-full hover:bg-green-600`}
                  disabled={isLoading || verified}
                  onClick={handleVerifyOTP}
                >
                  {isLoading ? "Verifying..." : "Verify"}
                </button> */}

              {/* </div> */}
              {/* {verified && (
              <div className="mt-4 flex justify-center">
                <button
                  className="bg-blue-500 text-white px-4 py-2 rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  onClick={handleNextStep}
                >
                  {isSplit ? "Loading" : "Submit your split Ticket"}
                </button>
              </div>
            )} */}
            </div>
          )}

          {/* Email Input */}
          {/* <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`w-full px-3 py-2 border ${
                formik.touched.email && formik.errors.email
                  ? "border-red-500"
                  : "border-gray-300"
              } rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500`}
            />
            {formik.touched.email && formik.errors.email && (
              <div className="text-red-500 text-sm">{formik.errors.email}</div>
            )}
          </div> */}

          {/* City Input */}
          {/* <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              City
            </label>
            <input
              type="text"
              id="city"
              name="city"
              value={formik.values.city}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`w-full px-3 py-2 border ${
                formik.touched.city && formik.errors.city
                  ? "border-red-500"
                  : "border-gray-300"
              } rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500`}
            />
            {formik.touched.city && formik.errors.city && (
              <div className="text-red-500 text-sm">{formik.errors.city}</div>
            )}
          </div> */}

          {/* Quantity Input */}
          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Quantity
            </label>
            <input
              type="number"
              id="quantity"
              name="quantity"
              value={formik.values.quantity}
              onChange={handleQuantityChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Submit Button */}
          {verified && (
            <div className="mt-4 flex justify-center">
              {
                isSplit ?  <>    <div
                className="bg-blue-500 text-white px-4 py-2 rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                Loading
              </div>  </>  :  <>    <div
                className="bg-blue-500 text-white px-4 py-2 rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                onClick={handleNextStep}
              >
                Submit your split Ticket
              </div>   </>
              }
              {/* <div
                className="bg-blue-500 text-white px-4 py-2 rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                onClick={handleNextStep}
                disabled={isSplit}
              >
                {isSplit ? "Loading" : "Submit your split Ticket"}
              </div> */}
            </div>
          )}

          {!verified && (
            <div className="flex justify-center">
              {!otpShow ? (
                <button
                  type="submit"
                  className={`px-4 py-2 rounded-md shadow-sm focus:outline-none focus:ring-2 ${
                    isOtpDisplay
                      ? "bg-gray-400 text-white cursor-not-allowed"
                      : "bg-blue-500 text-white hover:bg-blue-600 focus:ring-blue-500"
                  }`}
                  disabled={isOtpDisplay}
                  onClick={handleNextStep}
                >
                  {isSubmit ? "Loading..." : "Submit"}
                </button>
              ) : (
                <button
                  type="submit"
                  className={`px-4 py-2 rounded-md shadow-sm focus:outline-none focus:ring-2 ${
                    isOtpDisplay
                      ? "bg-gray-400 text-white cursor-not-allowed"
                      : "bg-blue-500 text-white hover:bg-blue-600 focus:ring-blue-500"
                  }`}
                  disabled={isOtpDisplay}
                >
                  Submit
                </button>
              )}
            </div>
          )}
        </form>

        {/* {otpShow && isOtpDisplay && (
          <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-md mt-4">
            <h2 className="text-xl font-semibold mb-4 text-center">
              Enter OTP
            </h2>
            <input
              type="text"
              placeholder="Enter your OTP"
              className="border border-gray-300 rounded-lg p-2 w-full mb-4"
              value={otp}
              disabled={verified}
              onChange={(e) => setOTP(e.target.value)}
            />

            <div className="">

            {cooldown < 1 ? (
              <>
              {
                isLoadingResendotp ?  <>  <p className="text-green-400 text-right mr-2 mb-3 cursor-pointer" 
              
                >
                 Sending
                </p> </> :    <>   <p className="text-green-400 text-right mr-2 mb-3 cursor-pointer" 
              onClick={() => handleSendOTP(uuiddata.mobile)}
              >
               Resend otp
              </p></>
              }
           
              </>

            ) : (
              <p className="text-red-400 text-right">{otpShow && cooldown > 0 && `Resend in ${cooldown}s`}</p>
            )}            
              
                <button
                  className={`${
                    verified
                      ? "bg-slate-200 text-gray-500"
                      : "bg-green-500 text-white"
                  } font-semibold rounded-lg p-2 w-full hover:bg-green-600`}
                  disabled={isLoading || verified}
                  onClick={handleVerifyOTP}
                >
                  {isLoading ? "Verifying..." : "Verify"}
                </button>
            
            </div>
            {verified && (
              <div className="mt-4 flex justify-center">
                <button
                  className="bg-blue-500 text-white px-4 py-2 rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  onClick={handleNextStep}
                >
                  {isSplit ? "Loading" : "Submit your split Ticket"}
                </button>
              </div>
            )}
          </div>
        )} */}

        {/* {!otpShow && isSubmit && (
          <div className="flex justify-center mt-4">
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
              onClick={handleNextStep}
              disabled={isSplit}
            >
              {isSplit ? "Loading" : "Submit your split Ticket"}
            </button>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default SplitForm;
