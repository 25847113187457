import React, { useEffect, useState } from "react";
import { FaTicketAlt } from "react-icons/fa"; // Importing the ticket icon
import { Splitticket } from "containers/store";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const Quantity: React.FC = () => {
  const dispatch = useDispatch();
  const { uuid } = useParams();
  const [uuiddata, setUuiddata] = useState<any>(null); // Initializing as null


  useEffect(() => {
    if (uuid) {
      dispatch(Splitticket(uuid)).then((res: any) => {
        if (res.meta.requestStatus === "fulfilled") {
          if (res.payload.getsplitticket?.success) {
            const fetchedData = res.payload.getsplitticket.data;
            setUuiddata(fetchedData);
            console.log(fetchedData, "Fetching Data uuiddd");
          } else {
            // console.log(res.payload.getsplitticket , 'fff')
            toast.error(res.payload.getsplitticket?.message ?? " ");
          }
        } else {
          console.log(res.error, "Error Fetching Data");
        }
      });
    }
  }, [uuid, dispatch]);



  // Calculate Available Quantity only if uuiddata is not null
  const AvailabelQty = uuiddata ? Math.max(0, (uuiddata.qty ?? 0) - (uuiddata.scanned_qty ?? 0)) : 0;


  return (
    <div className="container p-10 flex justify-center">
      <div className="relative flex items-center bg-gradient-to-r from-red-500 via-pink-500 to-red-500 text-white px-8 py-6 rounded-md shadow-lg w-fit">
        {/* Render quantity only if uuiddata is not null and qty is available */}
        {uuiddata && uuiddata.qty !== undefined ? (
          <>
            {/* Badge for Quantity */}
            <div className="absolute -top-3 -right-3 bg-white text-red-600 font-bold text-base md:text-lg rounded-full w-12 h-12 flex items-center justify-center shadow-md border-2 border-red-600">
              {AvailabelQty}
            </div>

            {/* Ticket Icon */}
            <FaTicketAlt className="text-5xl mr-5 animate-bounce" />

            {/* Alert Text */}
            <span className="text-base md:text-lg font-semibold tracking-wide">
              <span className="underline underline-offset-4">
                Available Ticket Quantity
              </span>
            </span>
          </>
        ) : (
          // Fallback when data is still loading or unavailable
          <span className="text-base md:text-lg font-semibold">Loading...</span>
        )}
      </div>
    </div>
  );
};

export default Quantity;
