import React from 'react';
import Paymentfailed from "../Assets/PaymentSuccess/payment-Faild.jpg"

const PaymentFailedModal: React.FC = () => {
  return (
    <div className='container mt-2'>
    <div className="flex items-center justify-center  bg-gray-100 p-4">
      <div className="bg-white rounded-lg shadow-lg p-6 max-w-md w-full relative">
        {/* Close Button */}
        {/* <button className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 focus:outline-none">
          &times;
        </button> */}

        {/* Image */}
        <div className="flex justify-center mb-4">
          <img 
            src={Paymentfailed}
            alt="Payment Failed" 
            className="w-full h-auto object-cover"
          />
        </div>

        {/* Title */}
        <h2 className="text-xl font-bold text-center text-red-500 mb-2">Payment Failed</h2>

        {/* Message */}
        <p className="text-center text-gray-600 mb-4">
          Unfortunately executing your payment failed. You can go back and try again.
        </p>

        {/* Try Again Button */}
        {/* <button className="w-full bg-pink-500 text-white py-3 rounded-lg hover:bg-pink-600 transition">
          Try again
        </button> */}
      </div>
    </div>
    </div>
  );
};

export default PaymentFailedModal;
