import React, { useEffect, useState } from "react";
import {
  Formik,
  Form as FormikForm,
  Field,
  ErrorMessage,
  FormikHelpers,
} from "formik";
import * as Yup from "yup";
import { SweetToast } from "utils/sweetAlert";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  GarbaFormAddList,
  GarbaFormEditList,
  UploadFile,
} from "containers/store";

interface FormValues {
  firstName: string;
  lastName: string;
  mobile: string;
  email: string;
  city: string;
  qty: number;
  photo?: any;
  aadhar_card?: any;
}

interface Props {
  initialValues: any;
  isEdit: boolean;
}

const validationSchema = Yup.object({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  mobile: Yup.string()
    .length(10, "Mobile number must be exactly 10 digits")
    .matches(/^\d+$/, "Mobile number must be digits only")
    .required("Mobile number is required"),
  email: Yup.string()
    .email("Invalid email format")
    .matches(
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
      "Invalid email format"
    )
    .max(100, "Email must be at most 100 characters long")
    .required("Email is required"),
  city: Yup.string().default("Ahmedabad"),
});

const GarbaClassIcardForm: React.FC<Props> = ({ initialValues, isEdit }) => {
  console.log(initialValues, "initialValues");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [photo, setPhoto] = useState<any>(initialValues.photo);
  const [aadharCard, setAadharCard] = useState<any>(initialValues.aadhar_card);
  const [formLoading, setFormLoading] = useState(false);
  const [aadharloading, setaadharLoading] = useState<boolean>(false);
  const [photoloading, setphotoLoading] = useState<boolean>(false);
  const [aadharPreview, setAadharPreview] = useState<string | null>(null);
  const [photoPreview, setPhotoPreview] = useState<string | null>(null);

  useEffect(() => {
    setAadharPreview(initialValues.aadhar_card ?? null);
    setPhotoPreview(initialValues.photo ?? null);
  }, [initialValues]);

  const handleSubmit = (
    values: FormValues,
    { resetForm }: FormikHelpers<FormValues>
  ) => {
    const fullName = `${values.firstName} ${values.lastName}`;
    const payLoad = {
      ...values,
      name: fullName,
      photo: photo,
      aadhar_card: aadharCard,
    };
    console.log("form payload", payLoad);
    console.log("form fullName", fullName);
    console.log(photo , 'photo')
    console.log(aadharCard , 'aadharCard')
    if (!photo) {
      SweetToast.error("Photo Field is Required");
    } else if (!aadharCard) {
      SweetToast.error("Aadhar Card Field is Required");
    } else {
      setFormLoading(true);
      // console.log(isEdit , 'isEdit')
      if (isEdit) {
        dispatch(GarbaFormEditList(payLoad)).then((data: any) => {
          setFormLoading(false);
          if (data?.payload?.garbaFormEditList?.success) {
            SweetToast.success("Submit successfull");
            navigate("/garba-class-form-list");
          } else {
            SweetToast.error(
              data?.payload?.message ?? "Please Try Again Later"
            );
          }
        });
      } else {
        dispatch(GarbaFormAddList(payLoad)).then((data: any) => {
          setFormLoading(false);
          if (data?.payload?.garbaFormAddList?.success) {
            SweetToast.success("Submit successful");
            navigate("/garba-class-form-list");
          } else {
            SweetToast.error(
              data?.payload?.message ?? "Please Try Again Later"
            );
          }
        });
      }
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue }) => (
          <FormikForm>
            <div className="grid gap-5 grid-cols-12 mt-10">
              {/* Form fields */}
              <div className="col-span-12 lg:col-span-6">
                <div className="mb-2">
                  <label className="block text-sm mx-1 font-medium text-gray-700">
                    Student Name<span className="text-red-500">*</span>
                  </label>
                  <div className="sm:flex  ">
                    <div className="mb-4 mx-0 md:mx-2 lg:mx-2  md:mb-0">
                      <Field
                        name="firstName"
                        type="text"
                        placeholder="First Name"
                        className="py-3 mx-0  w-full  border-gray-200 shadow-sm rounded-lg  sm:rounded-s-lg text-sm focus:border-blue-500 focus:ring-blue-500 uppercase-first-letter"
                      /> 
                      <ErrorMessage
                        name="firstName"
                        component="div"
                        className="text-red-600 text-sm mt-1 mx-3"
                      />
                    </div>
                    <div>
                      <Field
                        name="lastName"
                        type="text"
                        placeholder="Last Name"
                        className="py-3 mx-0  w-full  border-gray-200 shadow-sm rounded-lg rounded-e-lg text-sm focus:border-blue-500 focus:ring-blue-500 uppercase-first-letter"
                      />
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        className="text-red-600 text-sm mt-1 mx-3"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-12    lg:col-span-6">
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Mobile<span className="text-red-500">*</span>
                  </label>
                  <Field
                    type="text"
                    name="mobile"
                    maxLength={10}
                    pattern="[0-9]*" 
                    inputMode="numeric" 
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    onKeyPress={(e:any) => {
                      if (!/[0-9]/.test(e.key)) {
                        e.preventDefault(); // Prevent non-numeric input
                      }
                    }}
                  />
                  <ErrorMessage
                    name="mobile"
                    component="div"
                    className="text-red-600 text-sm mt-1"
                  />
                </div>
              </div>
              <div className="col-span-12    lg:col-span-6">
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Email<span className="text-red-500">*</span>
                  </label>
                  <Field
                    type="email"
                    name="email"
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-red-600 text-sm mt-1"
                  />
                </div>
              </div>
              <div className="col-span-12    lg:col-span-6">
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    City<span className="text-red-500">*</span>
                  </label>
                  <Field
                    type="text"
                    name="city"
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                  <ErrorMessage
                    name="city"
                    component="div"
                    className="text-red-600 text-sm mt-1"
                  />
                </div>
              </div>

              <div className="col-span-12 lg:col-span-6">
                <div className="mb-4">
                  <div className="grid grid-cols-12">
                    <div className="col-span-10 lg:col-span-8">
                      <label className="block text-sm font-medium text-gray-700">
                        Upload Aadhar Card<span className="text-red-500">*</span>
                      </label>
                      <input
                        name="aadhar_card" // Make sure this matches the validation schema
                        type="file"
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        onChange={(event: any) => {
                          if (event.target.files) {
                            console.log(
                              event.target.files[0],
                              "event.target.files[0]"
                            );
                            const payLoadForFile = {
                              file_upload: event.target.files[0],
                              type: "Events",
                              module_name: "events",
                            };
                            console.log(payLoadForFile, "payLoadForFile");
                            if (event.target.files[0]) {
                              setaadharLoading(true); // Set loading to true when upload starts
                              dispatch(UploadFile(payLoadForFile))
                                .then((data: any) => {
                                  setAadharPreview(
                                    data?.payload?.uploadFileData?.user_document
                                      ?.attachment ?? ""
                                  );
                                  setAadharCard(
                                    data?.payload?.uploadFileData?.user_document
                                      ?.attachment ?? ""
                                  );
                                  console.log(
                                    data?.payload?.uploadFileData?.user_document
                                      ?.attachment,
                                    "data"
                                  );
                                  setaadharLoading(false);
                                  // setFieldValue("photo", data?.payload?.uploadFileData?.user_document?.attachment); // Set the file to Formik's state
                                })
                                .catch(() => {
                                  setaadharLoading(false); // Ensure loading is stopped in case of an error
                                });
                            }
                          }
                        }}
                      />
                    </div>
                    <div className="col-span-2 lg:col-span-4">
                      {/* Aadhar Card Preview */}
                      {aadharloading ? (
                        <div className="mt-2 text-blue-600 text-sm">
                          Uploading...
                        </div>
                      ) : (
                        <div>
                          {aadharPreview && (
                            <div className="mt-2">
                              <img
                                src={aadharPreview}
                                alt="Uploaded Aadhar card preview"
                                className="h-10 w-10 object-cover rounded-lg" // Adjust dimensions as necessary
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <ErrorMessage
                      name="aadhar_card"
                      component="div"
                      className="text-red-600 text-sm mt-1"
                    />
                  </div>
                </div>
              </div>

              <div className="col-span-12 lg:col-span-6">
                <div className="mb-4">
                  <div className="grid grid-cols-12">
                    <div className="col-span-10 lg:col-span-8">
                      <label className="block text-sm font-medium text-gray-700">
                        Upload your Photo<span className="text-red-500">*</span>
                      </label>

                      <input
                        name="photo" // Make sure this matches the validation schema
                        type="file"
                        onChange={(event: any) => {
                          if (event.target.files) {
                            console.log(
                              event.target.files[0],
                              "event.target.files[0]"
                            );
                            const payLoadForFile = {
                              file_upload: event.target.files[0],
                              type: "Events",
                              module_name: "events",
                            };
                            console.log(payLoadForFile, "payLoadForFile");
                            if (event.target.files[0]) {
                              setphotoLoading(true);
                              dispatch(UploadFile(payLoadForFile))
                                .then((data: any) => {
                                  setPhotoPreview(
                                    data?.payload?.uploadFileData?.user_document
                                      ?.attachment ?? ""
                                  );
                                  setPhoto(
                                    data?.payload?.uploadFileData?.user_document
                                      ?.attachment ?? ""
                                  );
                                  console.log(
                                    data?.payload?.uploadFileData?.user_document
                                      ?.attachment,
                                    "data"
                                  );
                                  setphotoLoading(false);
                                })
                                .catch(() => {
                                  setphotoLoading(false); // Ensure loading is stopped in case of an error
                                });
                            }
                          }
                        }}
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-2 lg:col-span-4">
                      {/* Aadhar Card Preview */}

                      {photoloading ? (
                        <div className="mt-2 text-blue-600 text-sm">
                          Uploading...
                        </div>
                      ) : (
                        <div>
                          {photoPreview && (
                            <div className="mt-2">
                              <img
                                src={photoPreview}
                                alt="Uploaded Aadhar card preview"
                                className="h-10 w-10 object-cover rounded-lg" // Adjust dimensions as necessary
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <ErrorMessage
                      name="photo"
                      component="div"
                      className="text-red-600 text-sm mt-1"
                    />
                  </div>
                </div>
              </div>
            </div>
            {formLoading ? (
              <>
                <div className="flex items-center justify-center">
                  <button className="w-56 bg-blue-500 text-white font-medium py-2 px-4 rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 flex items-center justify-center">
                    <svg
                      className="animate-spin h-5 w-5 mr-2 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                      ></path>
                    </svg>
                    Loading...
                  </button>
                </div>
              </>
            ) : (
              <>
                {" "}
                <div className="flex justify-center items-center">
                  <button
                    disabled={photoloading || aadharloading}
                    type="submit"
                    className={`w-56 ${
                      photoloading || aadharloading
                        ? "bg-slate-500"
                        : "bg-blue-500"
                    }    text-white font-medium py-2 px-4 rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 `}
                  >
                    {photoloading || aadharloading
                      ? "Please Wait ... "
                      : isEdit
                      ? "Update"
                      : "Submit"}
                  </button>
                </div>{" "}
              </>
            )}
          </FormikForm>
        )}
      </Formik>
    </>
  );
};

export default GarbaClassIcardForm;
