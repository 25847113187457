import React from "react";
import { Link } from "react-router-dom";
import labhFlower from "../../Assets/labh-logo/labh_creation_flower.jpg";
import labhLogo from "../../Assets/labh-logo/Labh-Logo.png"

export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
}

const Logo: React.FC<LogoProps> = ({
  img = labhFlower,
  imgLight = labhFlower,
  className = "flex-shrink-0",
}) => {
  return (
    <Link
      to="/"
      className={`ttnc-logo inline-block text-slate-600 ${className}`}
    >
      <div className="flex gap-2 md:gap-4">
        <div>
          {/* THIS USE FOR MY CLIENT */}
          {/* PLEASE UNCOMMENT BELOW CODE AND USE IT */}
          {img ? (
            <img
              className={`block max-h-7 sm:max-h-18 md:max-h-[44px] lg:max-h-[44px] rounded-full${
                imgLight ? "dark:hidden" : ""
              } animate-spin duration-1000`}
              src={img}
              alt="Logo"
            />
          ) : (
            "Logo Here"
          )}
          {imgLight && (
            <img
              className="hidden max-h-10 sm:max-h-10 dark:block animate-spin"
              src={imgLight}
              alt="Logo-Light"
            />
          )}
        </div>
        <div className="flex items-center space-x-1 ">
          <img src={labhLogo} alt="labhLogo" className="w-[150px] h-[24px] md:w-[220px] md:h-[28px]"/>
        </div>
        {/* <div className="flex items-center space-x-1">
          <span className="font-bold text-red-600 text-2xl md:text-5xl">
            LABH
          </span>
          <span className="font-normal text-brown-700 text-1.5xl md:text-2xl mt-2 tracking-wide">
            CREATION
          </span>
        </div> */}
      </div>
    </Link>
  );
};

export default Logo;
