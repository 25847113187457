import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "../shared/Footer/Footer";
import PageHome from "../containers/PageHome/PageHome";
import Page404 from "../containers/Page404/Page404";
import AccountPage from "../containers/AccountPage/AccountPage";
// import PageContact1 from "containers/PageContact/PageContact1";
import PageContact from "../containers/PageContact/PageContact";
import PageAbout from "../containers/PageAbout/PageAbout";
import PageSignUp from "../containers/PageSignUp/PageSignUp";
import PageLogin from "../containers/PageLogin/PageLogin";
import PageSubcription from "../containers/PageSubcription/PageSubcription";
import BlogPage from "../containers/BlogPage/BlogPage";
import BlogSingle1 from "../containers/BlogPage/BlogSingle1";
import SiteHeader from "../containers/SiteHeader";
import PageCollection from "../containers/PageCollection";
import PageSearch from "../containers/PageSearch";
import PageHome2 from "../containers/PageHome/PageHome2";
import PageHome3 from "../containers/PageHome/PageHome3";
import ProductDetailPage from "../containers/ProductDetailPage/ProductDetailPage";
import ProductDetailPage2 from "../containers/ProductDetailPage/ProductDetailPage2";
import AccountSavelists from "../containers/AccountPage/AccountSavelists";
import AccountPass from "../containers/AccountPage/AccountPass";
import AccountBilling from "../containers/AccountPage/AccountBilling";
import AccountOrder from "../containers/AccountPage/AccountOrder";
import CartPage from "../containers/ProductDetailPage/CartPage";
// import CheckoutPage from "containers/PageCheckout/CheckoutPage";
// import CheckoutPage1 from "containers/PageCheckout/CheckoutPage1";
import PageCollection2 from "../containers/PageCollection2";
import { Toaster } from "react-hot-toast";
import OurProductCollection from "../containers/OurProductCollection";
import CheckoutPage from "../containers/PageCheckout/CheckoutPage";
import PaymentDetail from "../containers/PaymentInformation/PaymentDetail";
import EventForm from "../containers/PageHome/EventForm";
import HelpCenter from "../shared/Terms and condition/help";
import Privacy from "../shared/Terms and condition/Privacy";
import TermsCondition from "../shared/Terms and condition/Terms";
import Refunds from "../shared/Terms and condition/Refunds";
import OrchestaList from "../components/orchestaList/orchesta";
import IcardForm from "../components/IcardRegistrationForm/IcardForm";
// import IcardRules from "../components/IcardRegistrationForm/IcardRules";
import ForgotPassword from "../containers/forgetpassword/ForgotPassword";
import GarbaClassForm from "../components/GarbaClassForm";
import GarbaClassesDetail from "../components/GarbaClassesDetail";
import GarbaClassFormEdit from "../components/GarbaClassFormEdit";
import Splitform from "../components/SplitForm";
import PaymentSuccessPage from "components/PaymentSuccessPage";
import PaymentFailedModal from "components/PaymentFailedModal";
import { token } from "api";
import SuccessIcardPage from "../components/SuccessIcardPage";
import Quantity from "../components/Quantity";


export const pages: Page[] = [
  { path: "/", component: PageHome },
  { path: "/home2", component: PageHome2 },
  { path: "/home3", component: PageHome3 },
  //
  { path: "/home-header-2", component: PageHome },
  // { path: `/product-detail `, component: ProductDetailPage },
  { path: "/product-detail/:id", component: ProductDetailPage },
  { path: "/payment/:id", component: PaymentDetail },
  // { path: "/product-detail", component: ProductDetailPage },
  { path: "/product-detail-2", component: ProductDetailPage2 },
  { path: "/page-collection-2", component: PageCollection2 },
  { path: "/page-collection", component: PageCollection },
  { path: "/our-product", component: OurProductCollection },
  { path: "/page-search", component: PageSearch },
  
  { path: "/account", component: AccountPage },
  { path: "/account-savelists", component: AccountSavelists },
  { path: "/account-change-password", component: AccountPass },
  { path: "/account-billing", component: AccountBilling },
  { path: "/account-my-order", component: AccountOrder },
  //
  { path: "/cart", component: CartPage },
  { path: "/checkout", component: CheckoutPage },
  //
  { path: "/blog", component: BlogPage },
  { path: "/blog-single", component: BlogSingle1 },

  //
  { path: "/contact", component: PageContact },
  { path: "/about", component: PageAbout },
  { path: "/signup", component: PageSignUp },
  { path: "/login", component: PageLogin },
  { path: "/subscription", component: PageSubcription },
  
  //
  {path:"/eventForm", component:EventForm},
  {path:"/paymentdetail", component:PaymentDetail},
  {path:"/help", component:HelpCenter},
  {path:"/privacy", component:Privacy},
  {path:"/terms", component:TermsCondition},
  {path:"/refunds", component:Refunds},
  {path:"/orchesta", component:OrchestaList},

  // {path:"/IcardForm", component:IcardForm},
  
  {path:"/forgot-pass", component:ForgotPassword},
  {path:"/garbaclass", component: token ?  GarbaClassForm :PageHome },

  // {path:"/garba-class-form-add", component: token ? GarbaClassForm : PageHome },
  // {path:"/garba-class-form-edit/:id", component: token ? GarbaClassFormEdit : PageHome},
  // {path:"/garba-class-form-list", component: token ? GarbaClassesDetail :PageHome },
  
  {path: "/split/:uuid", component: Splitform },
  {path: "/payment-success-page/:uuid", component: PaymentSuccessPage },
  {path: "/payment-failed-page", component: PaymentFailedModal },
  {path: "/Success-Icard", component: SuccessIcardPage },
  {path: "/Ticket-quantity/:uuid", component: Quantity },
  
];

const MyRoutes = () => {
  return (
    <BrowserRouter>
      <Toaster />
      <ScrollToTop />
      <SiteHeader />
      <Routes>
        {pages.map(({ component: Component, path }, index) => {
          return <Route key={index} element={<Component />} path={path} />;
        })}
        <Route element={<Page404 />} />
      </Routes>

      <Footer />
    </BrowserRouter>
  );
};

export default MyRoutes;
