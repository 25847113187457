import React from "react";
import SuccessGif from "../Assets/gif/success gif.gif";

const SuccessIcardPage: React.FC = () => {
  return (
    <div className="flex items-center justify-center p-10 bg-gray-100">
      <div className="text-center">
        <p className="text-base md:text-2xl text-gray-600 font-bold mb-4">Collect Your Physical I-Card From Ticket window on 03/10/2024</p>
        <img
          src={SuccessGif}
          alt="Payment Successful"
          className="mx-auto w-[160px] h-[130px] rounded-full mb-4"
        />
        <h1 className="text-xl md:text-2xl font-bold text-green-600">
          Payment Successful!
        </h1>
        <p className="mt-2 text-gray-600">
          Thank you for your payment. Your transaction has been completed.
        </p>
      </div>
    </div>
  );
};

export default SuccessIcardPage;
