import React from "react";
import KausikOutdoor from "../Assets/kausikOutdoors.jpg";
import radioPartner from "../Assets/Radio-Partner.jpg";
import BookShow from "../Assets/BookMyShow.jpg";
import Heading from "shared/Heading/Heading";

// Define an array of sponsors with their logo and optional link
const sponsors = [
  { logo: KausikOutdoor, name: "Sponsor 1", link: "#" },
  { logo: radioPartner, name: "Sponsor 2", link: "#" },
  { logo: BookShow, name: "Sponsor 3", link: "#" },
];

const OurSponsor: React.FC = () => {
  return (
    <div className="our-sponsors  py-8">
      <div className="container mx-auto px-4">
        <div className="text-2xl flex justify-center font-semibold mb-2 text-center">
          <Heading>Our  <span className="text-[#172554]">Sponsors</span> </Heading>
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 gap-6">
          {sponsors.map((sponsor, index) => (
            <div key={index} className="flex items-center justify-center">
              <a
                href={sponsor.link}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-center bg-transparent"
              >
                <img
                  src={sponsor.logo}
                  alt={sponsor.name}
                  className="w-[180px] h-[110px] object-contain hover:opacity-80 transition-opacity duration-300"
                />
                <p></p>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurSponsor;
