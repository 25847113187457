import React from "react";
import EkaVideo from "../../Assets/Video/Eka Ras Garba video.mp4";
import EkaVideo2 from "../../Assets/Video/eka-video-2.mp4";
import EkaVideo3 from "../../Assets/Video/eka-video-3.mp4";
import NcPlayIcon from "shared/NcPlayIcon/NcPlayIcon";

// Array of video sources and titles
const videos = [
  { src: EkaVideo, title: "Video 1" },
  { src: EkaVideo2, title: "Video 2" },
  { src: EkaVideo3, title: "Video 3" },
  
];

const VideoSection: React.FC = () => {
  const [playingVideo, setPlayingVideo] = React.useState<number | null>(null);

  const handlePlayClick = (index: number) => {
    setPlayingVideo(index);
  };

  return (
    <div className="nc-SectionVideos">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 p-4">
        {videos.map((video, index) => (
          <div key={index} className="relative group aspect-w-16 aspect-h-9 bg-neutral-800 rounded-3xl overflow-hidden border-4 border-white dark:border-neutral-900 sm:rounded-[50px] sm:border-[10px]">
            {playingVideo === index ? (
              <video
                className="absolute top-0 left-0 w-full h-full"
                src={video.src}
                title={video.title}
                controls
                autoPlay
              />
            ) : (
              <>
                <div
                  onClick={() => handlePlayClick(index)}
                  className="cursor-pointer absolute inset-0 flex items-center justify-center z-10"
                >
                  <NcPlayIcon />
                </div>
                <video
                  className="absolute inset-0 w-full h-full object-cover rounded-3xl overflow-hidden"
                  src={video.src}
                  muted
                  style={{ filter: "blur(10px)", opacity: 0.6 }}
                />
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default VideoSection;
